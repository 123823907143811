<template>
  <div class="pl-md-3 pl-xs-1 d-flex flex-row align-center">
    <label class="avoore-checkbox path d-block mr-2" v-bind:class="{ 'theme--dark': $vuetify.theme.dark }"
      for="select-all-inbox">
      <input type="checkbox" class="d-inline-flex" v-model="selectAllInbox" id="select-all-inbox" />
      <svg viewBox="0 0 21 21">
        <path
          d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186">
        </path>
      </svg>
    </label>

    <v-toolbar-title>
      {{ heading }}
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" style="top: -2px" class="ml-2 red--text">{{
            filterIsOn
          }}</v-icon>
        </template>
        <span>{{ $t("tooltip.filter-is-on") }}</span>
      </v-tooltip>
    </v-toolbar-title>

    <v-dialog v-model="dialog" persistent width="600">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ $t('pagetitle.filter data') }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-autocomplete data-hintposition="middle-middle" data-position="bottom-left-aligned" hide-details="true"
                  v-model="filterValue.memoType" v-bind:disabled="isUpdating" v-bind:items="memoTypeList"
                  v-bind:menu-props="{
                    closeOnClick: true,
                    closeOnContentClick: true,
                  }" hide-selected deletable-chips small-chips v-bind:search-input.sync="searchInput"
                  v-on:change="searchInput = ''" chips color="blue-grey lighten-2" v-bind:label="$t('label.memo type')"
                  item-text="title" item-value="value" multiple clearable>
                  <template v-slot:selection="data">
                    <v-chip v-bind="data.attrs" v-bind:input-value="data.selected" close @click="data.select"
                      @click:close="remove(data.item)">
                      {{ data.item.title }}
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content v-html="data.item.title">
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete data-hintposition="middle-middle" data-position="bottom-left-aligned" hide-details="true"
                  v-model="filterValue.dataStatus" v-bind:disabled="isUpdating" v-bind:items="memoStatusList"
                  v-bind:menu-props="{
                    closeOnClick: true,
                    closeOnContentClick: true,
                  }" hide-selected deletable-chips small-chips v-bind:search-input.sync="searchInput"
                  v-on:change="searchInput = ''" chips color="blue-grey lighten-2" v-bind:label="$t('label.data status')"
                  item-text="title" item-value="value" multiple clearable>
                  <template v-slot:selection="data">
                    <v-chip v-bind="data.attrs" v-bind:input-value="data.selected" close @click="data.select"
                      @click:close="removeStatus(data.item)">
                      {{ data.item.title }}
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content v-html="data.item.title">
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
          <small>*{{ $t('text.by default all are checked') }}</small>
        </v-card-text>
        <v-card-actions class="d-flex justify-space-between bg-surface-variant">
          <div>
            <v-btn color="blue-darken-1" variant="text" @click="dialog = false">
              {{ $t('button.close') }}
            </v-btn>
          </div>
          <div>
            <v-btn class="ma-1" color="blue-darken-1" variant="text" @click="resetFilter()">
              {{ $t('button.reset') }}
            </v-btn>
            <v-btn class="ma-1" color="blue-darken-1" variant="text" @click="doFilter()">
              {{ $t('button.filter') }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-show="!enableAction" class="ml-md-4 ml-sm-0" v-bind="attrs" v-on="on" text icon
          color="blue lighten-2 ml-3" @click="menuAction('reload', 'reload')">
          <v-icon>{{ iconReload }}</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("tooltip.reload") }}</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-show="!enableAction" v-bind="attrs" v-on="on" text icon color="blue lighten-2 ml-3"
          @click="dialog = true">
          <v-icon>{{ iconFilter }}</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("tooltip.menu-filter") }}</span>
    </v-tooltip>

    <v-tooltip bottom v-if="!$vuetify.breakpoint.smAndDown && !enableAction">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field v-on="on" v-bind="attrs" v-model="searchQuery" :label="$t('label.search')"
          :placeholder="$t('label.memo-title-or-sender')" outlined dense clearable hide-details="true"
          class="ml-2 search-input" :class="{ 'min-w-300px': searchQuery }"></v-text-field>
      </template>
      <span>{{ $t("tooltip.type-title-or-sender-name-then-enter") }}</span>
    </v-tooltip>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import {
  mdiReload,
  mdiDotsVertical,
  mdiEmailSearchOutline,
  mdiFilterMenuOutline,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiArchiveArrowDownOutline,
  mdiTuneVariant,
} from "@mdi/js";
import toolbarMenu from "@/constants/toolbarmenu";

export default {
  name: "header-left",
  components: {},
  props: {
    heading: {
      type: String,
      default: "",
    },
    enableAction: {
      type: Boolean,
    },
    filterModal: {
      type: Boolean
    }
  },
  data: () => ({
    searchInput: "",
    iconReload: mdiReload,
    iconMenu: mdiDotsVertical,
    iconSearch: mdiEmailSearchOutline,
    iconFilter: mdiFilterMenuOutline,
    iconEye: mdiEyeOutline,
    iconEyeClosed: mdiEyeOffOutline,
    iconArchive: mdiArchiveArrowDownOutline,
    menus: toolbarMenu.inboxFilterMenu,
    iconFilterActive: mdiTuneVariant,
    showSearch: false,
    searchQuery: "",
    timeout: null,
    isUpdating: false,
    memoTypeList: [
      { title: 'Memo Biasa', value: 'biasa' },
      { title: 'Memo BOM', value: 'bom' },
      { title: 'Memo Subholding', value: 'subholding' },
      { title: 'Surat X Nusantara', value: 'xnusantara' },
    ],
    memoStatusList: [
      { title: 'Belum Dibaca', value: 'unread' },
      { title: 'Belum Didisposisikan', value: 'pending' },
      { title: 'Didisposisikan', value: 'delegated' },
      { title: 'Ada Umpan Balik', value: 'has_feedback' },
    ],
    filterValue: {
      memoType: [
        "biasa",
        "bom",
        "subholding",
        "xnusantara"],
      dataStatus: [
        'unread',
        'pending',
        'delegated',
        'has_feedback',
      ],
    }
  }),
  computed: {
    ...mapState("inbox", ["filterParams"]),

    dialog: {
      get() {
        return this.filterModal
      },
      set(val) {
        this.$emit("filterModal", val);
      },
    },
    filterIsOn() {
      const arrType = this.filterParams.type.split(',');
      const arrStatus = this.filterParams.only.split(',');

      if ((arrType.length === this.memoTypeList.length) && (this.memoStatusList.length === arrStatus.length )) return null;
      return this.iconFilterActive;
    },
    selectAllInbox: {
      get() {
        return this.$store.state.inbox.selectAllInbox;
      },
      set(e) {
        this.$store.commit("inbox/SET_SELECT_ALL_INBOX", e);
      },
    },
  },
  watch: {
    searchQuery(val) {
      if (val && val.length < 3) return;

      let params = this.filterParams;

      if (this.timeout) clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        params.filter = val ? val : "";
        this.loadInbox(params);
      }, 1000);
    },
  },
  methods: {
    ...mapActions(["setMessageBox"]),
    ...mapActions("inbox", ["loadInbox", "doSelectAllInbox"]),
    selectAll(e) {
      this.doSelectAllInbox(e.target.checked);
    },
    applyQuery() {
      this.showSearch = false;
      if (!this.searchQuery || this.searchQuery == "") return;
      let params = this.filterParams;
      params.filter = this.searchQuery;
      this.loadInbox(params);
    },
    menuAction(command, slug) {
      this.$emit("menuAction", { command: command, slug: slug });
    },
    doFilter() {
      let params = this.filterParams;
      params.type = this.filterValue.memoType.join();
      params.only = this.filterValue.dataStatus.join();
      this.loadInbox(params);
      this.timeout = setTimeout(() => {
        this.dialog = false
      }, 500);
    },
    resetFilter() {
      let params = this.filterParams;
      this.filterValue.memoType = [
        "biasa",
        "bom",
        "subholding",
        "xnusantara"]
      
      this.filterValue.dataStatus = [
        'unread',
        'pending',
        'delegated',
        'has_feedback',
      ]
      params.type = this.filterValue.memoType.join();
      params.only = this.filterValue.dataStatus.join();
      this.loadInbox(params);

      this.timeout = setTimeout(() => {
        this.dialog = false
      }, 500);
    },
    remove(item) {
      // console.log(this.filterValue.memoType)
      let index = this.filterValue.memoType.indexOf(item.value)
      if (index >= 0) this.filterValue.memoType.splice(index, 1)
    },
    removeStatus(item) {
      // console.log(item.value)
      // console.log(this.filterValue.dataStatus)
      let index = this.filterValue.dataStatus.indexOf(item.value)
      if (index >= 0) this.filterValue.dataStatus.splice(index, 1)
    },
  },
};
</script>